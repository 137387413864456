<template>
    <main class="efficiency">
        <router-link class="efficiency__link efficiency__link_back link link_red"
            v-if="isReportTotalLinkVisible"
            :to="{ name: 'EfficiencyTotal'}"
        >
            Вернуться к общим данным об эффективности
        </router-link>
        <form class="efficiency__settings" @submit.prevent>
            <h2 class="sr-only">Настройки отчета "Эффективность"</h2>
            <daterange-picker class="efficiency__daterange"
                v-model="reportDaterangeModel"
                name="reportDaterange"
            >
                Период отчета:
            </daterange-picker>
            <fieldset class="efficiency__radio-group efficiency__radio-group_modes">
                <legend class="efficiency__legend sr-only">Режим отчета</legend>
                <radio class="efficiency__radio efficiency__radio_mode"
                    v-for="mode in modes"
                    :key="mode.id"
                    :val="mode.id"
                    name="mode"
                    v-model="modeModel"
                >
                    {{mode.title}}
                </radio>
            </fieldset>
            <daterange-picker class="efficiency__daterange"
                v-if="modeId === 'compareOtherPeriod'"
                v-model="compareDaterangeModel"
                name="compareOtherPeriod"
            >
                Период сравнения:
            </daterange-picker>
            <efficiency-settings class="load-total__settings"  v-if="isShowRestaurantsFilter" />
        </form>
        <spinner class="efficiency__spinner" v-if="status === 'loading'" />
        <error-message class="efficiency__error-message" v-else-if="status === 'error'">
            Не удалось загрузить данные об эффективности.
        </error-message>
        <router-view
            class="efficiency__data"
            :status="status"
            :reportDaterange="reportDaterange"
            :compareDaterange="compareDaterange"
            :modes="modes"
            :modeId="modeId"
            :reportEfficiency="reportEfficiency"
            :compareEfficiency="compareEfficiency"
            :isReportTotalLinkVisible="isReportTotalLinkVisible"
            :isShowRestaurantsFilter="isShowRestaurantsFilter"
            :userRestaurants="userRestaurants"
        />
    </main>
</template>

<script>
    import { mapState, mapGetters, mapMutations, mapActions } from "vuex";
    import { daterangeKey, isDaterangeSet } from "@/helpers/daterange";
    import EfficiencySettings from "./efficiency/common/EfficiencySettings";
    import DaterangePicker from "@/components/DaterangePicker";

    export default {
        name: "Efficiency",
        data() {
            return {
                status: "loading"
            }
        },
        components: {
            EfficiencySettings,
            DaterangePicker
        },
        computed: {
            ...mapState({
                reportDaterange: state => state.report.daterange,
                reportRestaurants: state => state.report.restaurants,
                compareDaterange: state => state.efficiency.compareDaterange,
                modeId: state => state.efficiency.modeId,
                data: state => state.efficiency.data,
                isReportTotalLinkVisible: state => state.ui.isReportTotalLinkVisible,
                isShowRestaurantsFilter: state => state.ui.isShowRestaurantsFilter,
            }),
            ...mapGetters([
                "userRestaurants",
                "reportRestaurantsKey",
                "reportKey",
            ]),
            modes() {
                return [
                    { id: "base", title: "Базовые данные" },
                    { id: "compareOtherPeriod", title: "Сравнение с другим периодом" }
                ];
            },
            reportDaterangeModel: {
                get() {
                    return this.reportDaterange;
                },
                set(daterange) {
                    this.setReportDaterange(daterange);
                }
            },
            compareDaterangeModel: {
                get() {
                    return this.compareDaterange;
                },
                set(daterange) {
                    this.setEfficiencyCompareDaterange(daterange);
                }
            },
            modeModel: {
                get() {
                    return this.modeId;
                },
                set(modeId) {
                    this.setEfficiencyMode(modeId);
                }
            },
            requiredDateranges() {
                let dateranges = [this.reportDaterange];

                if (this.modeId === "compareOtherPeriod" && isDaterangeSet(this.compareDaterange)) {
                    dateranges.push(this.compareDaterange);
                }

                return dateranges;
            },
            requiredEfficiencys() {
                return this.requiredDateranges?.map(daterange => this.data[`${daterangeKey(daterange)}-${this.reportRestaurantsKey}`]);
            },
            reportEfficiency() {
                return this.data[this.reportKey];
            },
            compareEfficiency() {
                if (isDaterangeSet(this.compareDaterange)) {
                    return this.data[`${daterangeKey(this.compareDaterange)}-${this.reportRestaurantsKey}`];
                }
            }
        },
        methods: {
            ...mapMutations([
                "setEfficiencyMode",
                "setReportDaterange",
                "setEfficiencyCompareDaterange"
            ]),
            ...mapActions([
                "requestEfficiencies"
            ]),
            showReport() {
                this.status = "success";
            },
            loadEfficiencys() {
                this.status = "loading";

                let daterangesWithoutData = this.requiredDateranges.filter(
                    daterange => !Boolean(this.data[`${daterangeKey(daterange)}-${this.reportRestaurantsKey}`])
                );

                this.requestEfficiencies({
                    dateranges: daterangesWithoutData,
                    restaurants: this.reportRestaurants,
                    restaurantsKey: this.reportRestaurantsKey,
                }).then(() => {
                    this.showReport();
                }).catch(() => {
                    this.status = "error";
                });
            }
        },
        created() {
            if (this.userRestaurants?.ids?.length === 1) {
                this.$router.push({
                    name: "EfficiencyRestaurant",
                    params:{
                        id: this.userRestaurants?.ids[0]
                    }
                });
            } else {
                if (this.reportRestaurants.length === 0) {
                    this.status = "no-restaurants";
                } else if (this.requiredEfficiencys?.every(Boolean)) {
                    this.showReport();
                } else {
                    this.loadEfficiencys();
                }
            }
        },
        watch: {
            requiredEfficiencys(requiredEfficiencys) {
                if (this.reportRestaurants.length === 0) {
                    this.status = "no-restaurants";
                } else if (!requiredEfficiencys.every(Boolean)) {
                    this.loadEfficiencys();
                } else {
                    this.showReport();
                }
            }
        }
    }
</script>


<style lang="scss">
    .efficiency__link {
        &_back {
            display: inline-block;
            margin-bottom: 10px;

            @include desktop {
                margin-bottom: 15px;
            }
        }
    }
    .efficiency__daterange {
        margin-bottom: 10px;

        @include desktop {
            margin-bottom: 15px;
        }
    }
    .efficiency__radio-group {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;

        @include desktop {
            flex-direction: row;
            margin-bottom: 15px;
        }
    }
    .efficiency__radio {
        & + & {
            margin-top: 5px;

            @include desktop {
                margin: 0 0 0 20px;
            }
        }
    }
    .efficiency__legend {
        margin-bottom: 5px;

        @include desktop {
            margin-bottom: 10px;
        }
    }
</style>
